@font-face {
  font-family: "arabic-bengali";
  src: url(../public/fonts/AlMuslimeenaBengali.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  transition: all linear 0.15s;
  font-weight: 400;
  scroll-behavior: smooth;
}

.font-arabic {
  font-family: "arabic-bengali";
}

nav div:first-child {
  justify-content: end !important;
}

/* custom scrollbar */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

input[type="range"] {
  appearance: none;
  background: #fff;
  width: 100%;
  cursor: pointer;
  box-shadow: inset 0 0 3px #ccc;
  height: 0.65rem;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fbbf24;
  transition: all linear 0.1s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #c9ac0b;
}

button.active {
  background-color: #fbbf24 !important;
}

button.active span {
  color: #000 !important;
}

button.active i {
  color: #000 !important;
}

.button-group li button {
  position: relative;
}

.button-group li button::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 14px;
  background-color: #ccc;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.button-group li button:last-child::after {
  display: none;
}

.banner {
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 4rem 0;
  background-image: url(./../public/images/dashboard-banner.jpg);
  background-position: center;
  background-blend-mode: overlay;
  z-index: 0;
}

.banner::after {
  z-index: -1;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #fbbf24bf;
}

.translate-buttons button.active {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  color: #000 !important;
}

.ayat-no {
  background-image: url(../public/images/ayat-separator.png);
  width: 48px;
  height: 48px;
  line-height: 46px;
  font-size: 1rem;
  text-align: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.auth_container {
  height: 100%;
  overflow: auto;
}

.auth_background {
  background-image: url("./../public/images/auth_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.goog-te-combo {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
  border-radius: 0.5rem;
  margin: 0 !important;
  padding: 0.625rem !important;
  border-width: 1px;
}

.goog-te-combo:focus {
  border-color: rgb(250 204 21) !important;
}

.dark .goog-te-combo,
.dark a,
.dark #google_translate_element,
.dark .goog-te-gadget {
  background-color: rgb(31 41 55) !important; /* Tailwind's bg-gray-800 */
  color: rgb(229 231 235) !important; /* Tailwind's text-gray-200 */
  border-color: rgb(107 114 128) !important; /* Tailwind's border-gray-600 */
}

.dark .goog-te-combo:focus {
  border-color: rgb(202 138 4) !important; /* Tailwind's border-yellow-400 */
}

@media (min-width: 768px) {
  .auth_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

@media (max-width: 767px) {
  .auth_container {
    height: auto;
  }
}
